import { emptySplitApi } from "./base";

export const authApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		jwtLogin: build.mutation({
			query: (body) => ({
				url: "/auth/jwt/login",
				method: "POST",
				body,
			}),
		}),
		jwtLogout: build.mutation({
			query: () => ({
				url: "/auth/jwt/logout",
				method: "POST",
			}),
		}),
		cookieLogin: build.mutation({
			query: (body) => ({
				url: "/auth/cookie/login",
				method: "POST",
				body,
			}),
		}),
		cookieLogout: build.mutation({
			query: () => ({
				url: "/auth/cookie/logout",
				method: "POST",
			}),
		}),
		register: build.mutation({
			query: (body) => ({
				url: "/auth/register",
				method: "POST",
				body,
			}),
		}),
		createOrRegister: build.mutation({
			query: (body) => ({
				url: "/auth/create_or_get_user",
				method: "POST",
				body,
			}),
		}),
		loginWithBranchId: build.mutation({
			query: (body) => ({
				url: "/auth/login_with_branch_id",
				method: "POST",
				body,
			}),
		}),
		forgotPassword: build.mutation({
			query: (body) => ({
				url: "/auth/forgot-password",
				method: "POST",
				body,
			}),
		}),
		resetPassword: build.mutation({
			query: (body) => ({
				url: "/auth/reset-password",
				method: "POST",
				body,
			}),
		}),
		requestVerify: build.mutation({
			query: (body) => ({
				url: "/auth/request-verify-token",
				method: "POST",
				body,
			}),
		}),
		verify: build.mutation({
			query: (body) => ({
				url: "/auth/verify",
				method: "POST",
				body,
			}),
		}),

		me: build.query({
			query: () => ({
				url: "/users/me",
				method: "GET",
			}),
		}),
		myOrganization: build.query({
			query: () => ({
				url: "/users/my_organization",
				method: "GET",
			}),
		}),
		filteredUser: build.query({
			query: (params) => ({
				url: "users/filter",
				method: "GET",
				params,
			}),
		}),
		getUser: build.query({
			query: (id) => ({
				url: `/users/${id}`,
				method: "GET",
			}),
		}),
		getQrCode: build.query({
			query: (id) => ({
				url: `auth/qr_code`,
				method: "GET",
			}),
		}),
		updateUser: build.mutation({
			query: ({ id, body }) => ({
				url: `/users/update_user/${id}`,
				method: "PATCH",
				body,
			}),
		}),
		updatePassword: build.mutation({
			query: (body) => ({
				url: `/auth/update_password`,
				method: "POST",
				body,
			}),
		}),
		deleteUser: build.mutation({
			query: (id) => ({
				url: `/users/delete_user/${id}`,
				method: "DELETE",
			}),
		}),
		userByEmail: build.query({
			query: (email) => ({
				url: `/users/get_user_by_email/${email}`,
				method: "GET",
			}),
		}),
		userByPasscode: build.query({
			query: (passcode) => ({
				url: `/users/get_user_by_passcode/${passcode}`,
				method: "GET",
			}),
		}),
		userById: build.query({
			query: (id) => ({
				url: `/users/get_user/${id}`,
				method: "GET",
			}),
		}),
		meByBranch: build.query({
			query: (id) => ({
				url: "/users/me_by_branch",
				method: "GET",
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useJwtLoginMutation,
	useJwtLogoutMutation,
	useCookieLoginMutation,
	useCookieLogoutMutation,
	useRegisterMutation,
	useCreateOrRegisterMutation,
	useLoginWithBranchIdMutation,
	useForgotPasswordMutation,
	useResetPasswordMutation,
	useRequestVerifyMutation,
	useVerifyMutation,

	useMeQuery,
	useMyOrganizationQuery,
	useFilteredUserQuery,
	useGetUserQuery,
	useGetQrCodeQuery,
	useUpdateUserMutation,
	useDeleteUserMutation,

	useUserByEmailQuery,
	useUserByPasscodeQuery,
	useUserByIdQuery,
	useMeByBranchQuery,

	useUpdatePasswordMutation,
} = authApi;
