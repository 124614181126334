import { createSlice } from "@reduxjs/toolkit";
import { UPLOAD_STATUS } from "../../constants/uploadStatus";

const initialState = {
	currentSelect: {
		branchId: null,
		photographerId: null,
		selectDate: null,
	},
	archive: {
		upload_date: new Date(),
		photographer: null,
		photopass: "",
	},
	restArchive: [],
	photos: [],
	photosForOrder: [],
	photosForRestPhoto: [],
	photosForDelete: {
		file_keys: [],
	},
	zoomPhotoForDelete: {
		file_keys: [],
	},
	photosForDownload: [],
};

export const archiveSlice = createSlice({
	name: "archive",
	initialState,
	reducers: {
		setArchiveForm: (state, action) => {
			state.archive[action.payload.field] = action.payload.value;
		},

		resetArchiveForm: (state) => {
			state.archive.upload_date = new Date();
			state.archive.photographer = null;
		},

		storeRestArchive: (state, action) => {
			state.restArchive = action.payload;
		},

		setArchivePhotos: (state, action) => {
			const images = action.payload;

			let imageBox = [];
			images.forEach((image) => {
				const newImage = {
					status: UPLOAD_STATUS.UPLOADING,
					progress: 0,
					loadedSize: 0,
					isUploading: false,
					...image,
				};
				imageBox.push(newImage);
			});

			let updatedPhotos = [...state.photos, ...imageBox];
			state.photos = updatedPhotos;
			// localStorage.setItem("archivePhotos", JSON.stringify(updatedPhotos));
		},

		setUpdateOfUpload: (state, action) => {
			if (action.payload.status) {
				state.photos[action.payload.id].status = action.payload.status;
			}
			// localStorage.setItem("archivePhotos", JSON.stringify(state.photos));
		},

		setUpdateOfProgress: (state, action) => {
			state.photos[action.payload.idx].progress = action.payload.progress;
			state.photos[action.payload.idx].loadedSize = action.payload.loadedSize;
		},

		setUpdateUploadingStatus: (state, action) => {
			state.photos[action.payload.idx].isUploading = action.payload.isUploading;
		},

		resetArchivePhotos: (state, action) => {
			state.photos = [];
		},

		setArchivePhotosForOrder: (state, action) => {
			state.photosForOrder = action.payload;
		},

		setArchivePhotosForRestPhoto: (state, action) => {
			state.photosForRestPhoto = action.payload;
		},

		removeCanceledUploadPhotos: (state, action) => {
			if (action.payload) {
				state.photos = state.photos.filter((photo) => photo.progress === 100);
			}
		},

		removePhotoFromOrder: (state, action) => {
			state.photosForOrder = state.photosForOrder.filter((photo) => photo.file_key !== action.payload.file_key);
		},

		removePhotoFromRestPhoto: (state, action) => {
			state.photosForRestPhoto = state.photosForRestPhoto.filter(
				(photo) => photo.file_key !== action.payload.file_key,
			);
		},

		resetArchivePhotosForOrder: (state) => {
			state.photosForOrder = [];
		},
		resetArchivePhotosForRestPhoto: (state) => {
			state.photosForRestPhoto = [];
		},
		resetArchivePhotosForDownload: (state) => {
			state.photosForDownload = [];
		},

		saveUpdateArchiveToLocalStorage: (state) => {
			// localStorage.setItem("archivePhotos", JSON.stringify(state));
		},

		// select photos for delete
		setArchivePhotosForDelete: (state, action) => {
			if (state.photosForDelete.file_keys.some((fileKey) => fileKey === action.payload)) {
				state.photosForDelete.file_keys = state.photosForDelete.file_keys.filter(
					(fileKey) => fileKey !== action.payload,
				);
			} else {
				state.photosForDelete.file_keys = [...state.photosForDelete.file_keys, action.payload];
			}
		},

		// slect archive photos for download
		setArchivePhotosForDownload: (state, action) => {
			// state.photosForDownload = [...state.photosForDownload, action.payload];

			if (state.photosForDownload.some((fileUrl) => fileUrl === action.payload)) {
				state.photosForDownload = state.photosForDownload.filter((fileUrl) => fileUrl !== action.payload);
			} else {
				state.photosForDownload = [...state.photosForDownload, action.payload];
			}
		},

		// select photos for delete
		setzoomPhotoForDelete: (state, action) => {
			if (state.zoomPhotoForDelete.file_keys.some((fileKey) => fileKey === action.payload)) {
				state.zoomPhotoForDelete.file_keys = state.zoomPhotoForDelete.file_keys.filter(
					(fileKey) => fileKey !== action.payload,
				);
			} else {
				state.zoomPhotoForDelete.file_keys = [...state.zoomPhotoForDelete.file_keys, action.payload];
			}
			// state.zoomPhotoForDelete.file_keys = [action.payload];
		},

		// Select all photos to archive
		setSelectAllArchive: (state, action) => {
			action.payload.forEach((item) => {
				if (!state.photosForDelete.file_keys.some((fileKey) => fileKey === item.file_key)) {
					state.photosForDelete.file_keys = [...state.photosForDelete.file_keys, item.file_key];
				}

				if (!state.photosForDownload.some((fileUrl) => fileUrl === item.fileUrl)) {
					state.photosForDownload = [...state.photosForDownload, item.file_url];
				}

				if (!state.zoomPhotoForDelete.file_keys.some((fileKey) => fileKey === item.file_key)) {
					state.zoomPhotoForDelete.file_keys = [...state.zoomPhotoForDelete.file_keys, item.file_key];
				}

				if (!state.photosForOrder.some((photo) => photo.file_key === item?.file_key)) {
					state.photosForOrder = [...state.photosForOrder, item];
				}
			});
		},

		// Unselect all photos to archive
		setUnSelectAllArchive: (state, action) => {
			action.payload.forEach((item) => {
				if (state.photosForDelete.file_keys.some((fileKey) => fileKey === item.file_key)) {
					state.photosForDelete.file_keys = state.photosForDelete.file_keys.filter(
						(fileKey) => fileKey !== item.file_key,
					);
				}

				if (state.photosForDownload.some((fileUrl) => fileUrl === item.fileUrl)) {
					state.photosForDownload = state.photosForDownload.filter((fileUrl) => fileUrl !== item.file_url);
				}

				if (state.zoomPhotoForDelete.file_keys.some((fileKey) => fileKey === item.file_key)) {
					state.zoomPhotoForDelete.file_keys = state.zoomPhotoForDelete.file_keys.filter(
						(fileKey) => fileKey !== item.file_key,
					);
				}

				if (state.photosForOrder.some((photo) => photo.file_key === item?.file_key)) {
					state.photosForOrder = state.photosForOrder.filter((photo) => photo.file_key !== item.file_key);
				}
			});
		},

		// select photos for delete
		// setArchivePhotosForDelete: (state, action) => {
		// 	const { file_key } = action.payload;

		// 	// store file_key
		// 	if (state.photosForDelete.file_keys.some((fileKey) => fileKey === file_key)) {
		// 		state.photosForDelete.file_keys = state.photosForDelete.file_keys.filter(
		// 			(fileKey) => fileKey !== file_key,
		// 		);
		// 	} else {
		// 		state.photosForDelete.file_keys = [...state.photosForDelete.file_keys, file_key];
		// 	}

		// 	// store file_url
		// 	if (state.selectedPhotosForDownload.some((item) => item.file_key === file_key)) {
		// 		state.selectedPhotosForDownload = state.selectedPhotosForDownload.filter(
		// 			(item) => item.file_key !== file_key,
		// 		);
		// 	} else {
		// 		state.selectedPhotosForDownload = [...state.selectedPhotosForDownload, action.payload];
		// 	}
		// },

		resetArchivePhotosForDelete: (state, action) => {
			state.photosForDelete.file_keys = [];
		},

		setCurrentSelect: (state, action) => {
			state.currentSelect.branchId = action.payload.branchId;
			state.currentSelect.photographerId = action.payload.photographerId;
			state.currentSelect.selectDate = action.payload.selectDate;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	storeRestArchive,
	setArchiveForm,
	resetArchiveForm,
	setArchivePhotos,
	setUpdateOfUpload,
	setUpdateOfProgress,
	setUpdateUploadingStatus,
	resetArchivePhotos,
	removeCanceledUploadPhotos,
	setArchivePhotosForOrder,
	setArchivePhotosForRestPhoto,
	resetArchivePhotosForOrder,
	resetArchivePhotosForRestPhoto,
	removePhotoFromOrder,
	removePhotoFromRestPhoto,
	saveUpdateArchiveToLocalStorage,
	setArchivePhotosForDelete,
	setArchivePhotosForDownload,
	resetArchivePhotosForDelete,
	resetArchivePhotosForDownload,
	setzoomPhotoForDelete,
	setSelectAllArchive,
	setUnSelectAllArchive,
	setCurrentSelect,
} = archiveSlice.actions;

export default archiveSlice.reducer;
